<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <b-img
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      />
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="changeLangue(localeObj)"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import vi from '@/libs/validate/vi.json'
import en from '@/libs/validate/en.json'

// eslint-disable-next-line no-unused-vars
import { localize } from 'vee-validate'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'English',
      },
      {
        locale: 'vi',
        img: require('@/assets/images/flags/vietnam.png'),
        name: 'VietNam',
      },
    ]
    /* eslint-disable global-require */

    return {
      locales,
      // $i18n.locale = localeObj.locale
    }
  },
  methods: {
    // eslint-disable-next-line no-shadow
    changeLangue(localeObj) {
      // eslint-disable-next-line no-undef
      if (localeObj.locale === 'en') {
        localize('en', en)
      } else {
        localize('vi', vi)
      }
      i18n.locale = localeObj.locale
    },
  },
}
</script>

<style>

</style>
