<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">
          CRAZY ENGLISH
        </h2>
      </b-link>

      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            style="max-width: 49%"
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            {{ $t('admin_title') }}
          </b-card-title>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <!--                  <b-link :to="{name:'auth-forgot-password'}">-->
                  <!--                    <small>{{ $t('forgot_pass')}}?</small>-->
                  <!--                  </b-link>-->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <small
                v-if="msg"
                class="text-danger"
              >{{ msg }}</small>
              <b-button
                type="submit"
                variant="primary"
                class="mt-1"
                block
                :disabled="invalid"
              >
                {{ $t('sign_in') }}
              </b-button>
            </b-form>
          </validation-observer>
          <b-navbar-nav class="nav align-items-right">
            <locale />
          </b-navbar-nav>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/extensions
import Locale from '@core/layouts/components/app-navbar/components/Locale'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BNavbarNav, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _ from 'lodash'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BNavbarNav,
    Locale,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      msg: this.$route.params.msg,
      userEmail: '',
      sideImg: require('@/assets/images/pages/login/login.png'),

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login/login.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              const tmpUser = response.data.user
              const roles = response.data.user.roles.map(v => v.name).join(',')
              const ability = response.data.user.roles.map(v => v.permissions.map(x => {
                const [subject, action] = x.name.split('_')
                return {
                  action,
                  subject,
                }
              }))

              ability.push({
                action: 'read',
                subject: 'Auth',
              })

              ability.push({
                action: 'read',
                subject: 'dashboard',
              })
              const userData = {
                id: tmpUser.id,
                fullName: tmpUser.name,
                username: tmpUser.name,
                avatar: tmpUser.image ? tmpUser.image.path : require('@/assets/images/logo/logo.png'),
                email: tmpUser.email,
                role: roles,
                status: tmpUser.status,
                created: tmpUser.created,
                dark_mode: tmpUser.dark_mode,
                ability: _.flatten(ability),
                extras: {
                  eCommerceCartItemsCount: 5,
                },
              }

              // commit user data
              this.$store.commit('appConfig/UPDATE_SKIN', tmpUser.dark_mode)
              this.$store.commit('user/UPDATE_USER', userData)

              useJwt.setToken(response.data.accessToken)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$ability.update(userData.ability)

              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `${this.$t('wlc')} ${userData.fullName || userData.username}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `${this.$t('logged_id')} ${userData.role}.${this.$t('now_start')}`,
                    },
                  })
                })
            })
            .catch(error => {
              console.log('error', error.response)
              let errorMsg = {}
              if (error.response.status === 422) {
                errorMsg = error.response.data
              } else {
                errorMsg.email = [error.response.data.error]
              }
              this.$refs.loginForm.setErrors(errorMsg)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
